var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cover"},[_c('div',{staticClass:"stepOnePage"},[_c('div',{staticClass:"home-title"},[_c('a-input',{attrs:{"placeholder":"请输入需求名称"},model:{value:(_vm.basicInfo3.purchaseTitle),callback:function ($$v) {_vm.$set(_vm.basicInfo3, "purchaseTitle", $$v)},expression:"basicInfo3.purchaseTitle"}})],1),_c('div',{staticClass:"completeInformation-form"},[_vm._m(0),_c('a-form-model',{ref:"formC",staticClass:"basicInfo-form",attrs:{"model":_vm.basicInfo3,"rules":_vm.rules3,"label-col":{
                    span: 3,
                },"wrapper-col":{
    span: 20,
},"colon":false}},[_c('a-form-model-item',{attrs:{"label":"参与生产商数量","prop":"participantCount"}},[_c('a-radio-group',{staticStyle:{"padding-top":"9px"},model:{value:(_vm.basicInfo3.participantCount),callback:function ($$v) {_vm.$set(_vm.basicInfo3, "participantCount", $$v)},expression:"basicInfo3.participantCount"}},_vm._l((20),function(item){return _c('a-radio',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" 个")])}),1)],1),_c('a-form-model-item',{attrs:{"label":"需求类型","prop":"purchaseType"}},[_c('j-multiSelectTag',{attrs:{"dictCode":"1043","placeholder":"请选择","optionType":"checkBox"},model:{value:(_vm.basicInfo3.purchaseType),callback:function ($$v) {_vm.$set(_vm.basicInfo3, "purchaseType", $$v)},expression:"basicInfo3.purchaseType"}})],1),_c('a-form-model-item',{attrs:{"label":"付款方式","prop":"paymentMethod"}},[_c('j-multiSelectTag',{attrs:{"dictCode":"1044","placeholder":"请选择","optionType":"checkBox"},model:{value:(_vm.basicInfo3.paymentMethod),callback:function ($$v) {_vm.$set(_vm.basicInfo3, "paymentMethod", $$v)},expression:"basicInfo3.paymentMethod"}})],1)],1)],1),_c('div',{staticClass:"completeInformation-form"},[_vm._m(1),_c('a-form-model',{ref:"formB",staticClass:"basicInfo-form",attrs:{"model":_vm.formLast,"label-col":{
                    span: 3,
                },"wrapper-col":{
    span: 20,
},"colon":false}},[_c('a-form-model-item',{attrs:{"label":"包装材料","prop":"packagingMaterial","rules":{
                        required: true,
                        message: '请选择包装材料',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-dictSelect',{attrs:{"dictCode":"1507","placeholder":"请选择","optionType":"radio"},model:{value:(_vm.formLast.packagingMaterial),callback:function ($$v) {_vm.$set(_vm.formLast, "packagingMaterial", $$v)},expression:"formLast.packagingMaterial"}})],1),_c('a-form-model-item',{attrs:{"label":"用途","prop":"purpose","rules":{
                        required: true,
                        message: '请选择用途',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-multiSelectTag',{attrs:{"dictCode":"1508","placeholder":"请选择","optionType":"checkBox"},model:{value:(_vm.formLast.purpose),callback:function ($$v) {_vm.$set(_vm.formLast, "purpose", $$v)},expression:"formLast.purpose"}})],1),_c('a-form-model-item',{attrs:{"label":"价格类型"}},[_c('j-multiSelectTag',{attrs:{"dictCode":"1505","placeholder":"请选择","optionType":"priceType"},model:{value:(_vm.formLast.priceType),callback:function ($$v) {_vm.$set(_vm.formLast, "priceType", $$v)},expression:"formLast.priceType"}})],1),_c('a-form-model-item',{attrs:{"label":"盒型","prop":"boxShape","rules":{
                        required: true,
                        message: '请选择盒型',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-dictSelect',{staticStyle:{"padding-top":"9px"},attrs:{"dictCode":"1042","placeholder":"请选择","optionType":"1042"},model:{value:(_vm.formLast.boxShape),callback:function ($$v) {_vm.$set(_vm.formLast, "boxShape", $$v)},expression:"formLast.boxShape"}})],1),_c('a-form-model-item',{attrs:{"label":"瓦楞楞型","prop":"corrugatedType","rules":{
                        required: true,
                        message: '请选择瓦楞楞型',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-dictSelect',{staticStyle:{"padding-top":"9px"},attrs:{"dictCode":"1022","placeholder":"请选择","optionType":"radio"},model:{value:(_vm.formLast.corrugatedType),callback:function ($$v) {_vm.$set(_vm.formLast, "corrugatedType", $$v)},expression:"formLast.corrugatedType"}})],1),_c('a-form-model-item',{attrs:{"label":"彩印的面纸"}},[_c('a-radio-group',{model:{value:(_vm.formLast.colorPrinting),callback:function ($$v) {_vm.$set(_vm.formLast, "colorPrinting", $$v)},expression:"formLast.colorPrinting"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否")])],1)],1),_c('a-form-model-item',{attrs:{"label":"瓦楞层数","prop":"corrugatedLayers","rules":{
                        required: true,
                        message: '请选择瓦楞层数',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-dictSelect',{attrs:{"dictCode":"1020","placeholder":"请选择","optionType":"radio"},model:{value:(_vm.formLast.corrugatedLayers),callback:function ($$v) {_vm.$set(_vm.formLast, "corrugatedLayers", $$v)},expression:"formLast.corrugatedLayers"}})],1),_c('a-form-model-item',{attrs:{"label":"印刷颜色","prop":"printingColor","rules":{
                        required: true,
                        message: '请选择印刷颜色',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-dictSelect',{attrs:{"dictCode":"1504","placeholder":"请选择","optionType":"radio"},model:{value:(_vm.formLast.printingColor),callback:function ($$v) {_vm.$set(_vm.formLast, "printingColor", $$v)},expression:"formLast.printingColor"}})],1),_c('a-form-model-item',{attrs:{"label":"印刷表面加油","prop":"printSurfaceOiling","rules":{
                        required: true,
                        message: '请选择印刷表面加油',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-dictSelect',{attrs:{"dictCode":"1506","placeholder":"请选择","optionType":"radio"},model:{value:(_vm.formLast.printSurfaceOiling),callback:function ($$v) {_vm.$set(_vm.formLast, "printSurfaceOiling", $$v)},expression:"formLast.printSurfaceOiling"}})],1),_c('a-form-model-item',{attrs:{"label":"印刷表面覆膜","prop":"printSurfaceCoating","rules":{
                        required: true,
                        message: '请选择印刷表面覆膜',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-dictSelect',{attrs:{"dictCode":"1509","placeholder":"请选择","optionType":"radio"},model:{value:(_vm.formLast.printSurfaceCoating),callback:function ($$v) {_vm.$set(_vm.formLast, "printSurfaceCoating", $$v)},expression:"formLast.printSurfaceCoating"}})],1),_c('a-form-model-item',{attrs:{"label":"粘箱钉箱","prop":"forming","rules":{
                        required: true,
                        message: '请选择粘箱钉箱',
                        trigger: ['change', 'blur'],
                    }}},[_c('j-multiSelectTag',{attrs:{"dictCode":"1023","placeholder":"请选择","optionType":"priceType"},model:{value:(_vm.formLast.forming),callback:function ($$v) {_vm.$set(_vm.formLast, "forming", $$v)},expression:"formLast.forming"}})],1)],1)],1),_c('a-row',[_c('a-col',{staticStyle:{"text-align":"center","margin":"30px 0 20px"},attrs:{"span":24}},[_c('a-button',{staticStyle:{"width":"120px","height":"38px","font-weight":"500","color":"#262626"},on:{"click":_vm.handleCallBack}},[_vm._v("取消")]),_c('a-button',{staticStyle:{"width":"120px","height":"38px","font-weight":"500","margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.handleSubmitData}},[_vm._v("预览需求单")])],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"completeInformation-form-title"},[_c('span',{staticClass:"title"},[_vm._v("基本信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"completeInformation-form-title"},[_c('span',{staticClass:"title"},[_vm._v("散件(SKU)详细信息")])])
}]

export { render, staticRenderFns }